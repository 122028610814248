import { Col, Row } from "react-bootstrap";
import logo from '../../assets/logo1.png'
import facebook from '../../assets/facebook.svg'
import x from '../../assets/x.svg'
import instagram from '../../assets/instagram.svg'
import youtube from '../../assets/youtube.svg'
import './style.css'
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Footer = () => {
    const data = useSelector(state=> state?.social)

    return <div className="footer">
        <div className="container">
            <Row>
                <Col md={4} sm={12} className="logo">
                    <img src={logo} alt='logo' width='120' />
                </Col>
                <Col md={4} sm={12} className="social">
                    <div>
                        {(!!data?.facebook || !!data?.instagram ||
                            !!data.twitter || !!data?.you_tube) && <p>تابعنا علي</p>}
                        <div className="icons">
                            {data?.facebook && <a href={data?.facebook} target='_blank'>
                                <img src={facebook} alt='facebook' />
                            </a>}
                            {data?.instagram && <a href={data?.instagram} target='_blank'>
                                <img src={instagram} alt='instagram' />
                            </a>}
                            {data.twitter && <a href={data.twitter} target='_blank'>
                                <img src={x} alt='x' />
                            </a>}
                            {data?.you_tube && <a href={data?.you_tube} target='_blank'>
                                <img src={youtube} alt='youtube' />
                            </a>}
                        </div>
                    </div>
                </Col>
                <Col md={4} sm={12} className="apps">
                    <div>
                        <Link to='/privacy'>سياسة الخصوصية</Link>
                    </div>
                </Col>
            </Row>
            <hr />
            <div>
                <p className="text-center mb-0 power">Powered By 
                <a 
                    href='https://www.leapsolutionskw.com' 
                    target='_blank'
                    style={{
                        color: '#fff',
                        textDecoration: 'none',
                        margin: '0 4px'
                    }}
                >Leap solutions kw</a>
                &copy; {new Date().getFullYear()}</p>
            </div>
        </div>
    </div>
}
export default Footer;