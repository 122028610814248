import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import talbat from '../../assets/talbat.png'
import './style.css'

const Talabat = () => {
    return <div className="talabat">
        <h1 className='title'>
         أعضاء فريق رؤية التطوعي
        </h1>
        <img src={talbat} alt='talabat' className='talabat-img' />
    </div>
}
export default Talabat;