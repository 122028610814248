const Title = () => {
    return <div className="container mt-5">
        <p 
            style={{
                color: '#289566',
                fontWeight: '700',
                fontSize: '26px',
                margin: '0 0 0 30px'
            }}
        >
            إيمانا منا بنشر ثقافة العمل التطوعي في مجتمعنا ومن اجل الإرتقاء بروح المبادرة والمشاركة الإيجابية لأفراد المجتمع لتحقيق الصالح العام تم الإتفاق لتأسيس فريق رؤية التطوعي
        </p>
    </div>
}
export default Title